import { Component, Vue } from 'vue-property-decorator'

import { api, getURL } from '@/services/api'

//components
import PortalAdminContent from '@/components/portalAdminContent/PortalAdminContent.vue'

// services
import { getAllMapTiposDoc } from '@/services/documentosService'

//warppets
import { 
  wIcon,
  Breadcrumb
} from '@/components/wrappers';

@Component({
  components: {
    Breadcrumb,
    wIcon,
    PortalAdminContent
  }
})
export default class Home extends Vue {

  noticias = []

  arrTiposDocs = getAllMapTiposDoc()

  

  mounted() {
    // this.getNoticias()
  }

  getNoticias(){
    const success = (response: any) => {
      console.log(response.data);
      this.noticias = response.data.posts
    }
    const error = (error: any) => {
      console.log(error);
    }
    const url = getURL('/get_posts/?post_type=boletim')
    api.get(url)
      .then(success)
      .catch(error)
  }

}
